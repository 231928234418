<template>
  <div>
    <v-menu
      offset-y
      v-model="mainMenuIsOpen"
    >
      <template v-slot:activator="{ on, value }">
        <v-btn
          data-cy="bulk-actions-button"
          :disabled="!search.bulkActions.length && ((search.selectedCount === 0 || search.selectedCount === search.resultCount) && !search.isShowSelectedItems)"
          v-on="on"
          x-small
          :icon="!search.bulkActions.length"
          :fab="!!search.bulkActions.length"
          max-width="20"
          max-height="20"
        >
          <icon-tooltip
            v-if="search.bulkActions.length || ((search.selectedCount > 0 && search.selectedCount !== search.resultCount) || search.isShowSelectedItems)"
            small
            color="secondary"
            icon-name="i.ChevronDown"
            :tooltip-text="$t('t.BulkAction')"
            :class-icon="{'rotate-180': value}"
          />
        </v-btn>
      </template>
      <v-sheet data-cy="bulk-actions-list">
        <v-list
          class="pa-0"
          subheader
        >
          <v-list-item
            v-if="search.selectedCount !== search.resultCount || search.isShowSelectedItems"
            link
            @click="search.showSelectedItems().execute()"
          >
            <v-list-item-action>
              <v-icon small>{{$icon('i.CheckAll')}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <span>{{labelShowSelectedItems}}</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop>
            <v-list-item-action class="mr-4">
              <v-switch
                @change="search.setKeepSelectionAfterAction($event)"
                :input-value="search.keepSelectionAfterAction"
              />
            </v-list-item-action>
            <v-list-item-content>
              <span>{{$t('t.KeepSelection')}}</span>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="orderedActions.length" />
          <template v-if="hasMultipleGroups">
            <v-list-group
              :data-cy="'bulk-actions-group-' + category"
              v-for="(actions, category) of groupedActions"
              :key="category"
              :value="openDefaultMenu(category)"
              @click.stop
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{category}}</v-list-item-title>
                </v-list-item-content>
              </template>
              <action-list
                :actions="actions"
                @click="confirmAction"
              />
            </v-list-group>
          </template>
          <template v-else>
            <action-list
              :actions="orderedActions"
              @click="confirmAction"
            />
          </template>
        </v-list>
      </v-sheet>
    </v-menu>
    <v-dialog
      v-if="actionToConfirm "
      v-model="showConfirmPopUp"
      width="clamp(30%, 400px, 100%)"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text class="d-flex-flex-column">
          <div class="text-body-1">
            <span class="mr-1">{{actionToConfirm.description}}</span>
            <span v-if="search.documentType === 'accounts'">{{$t('t.OnSelectedAccounts')}}</span>
            <span v-else-if="search.documentType === 'invoices'">{{$t('t.OnSelectedInvoices')}}</span>
            <span v-else-if="search.documentType === 'disputes'">{{$t('t.OnSelectedDisputes')}}</span>
            <span v-else-if="search.documentType === 'promises'">{{$t('t.OnSelectedPromises')}}</span>
            <span v-else-if="search.documentType === 'collaboration-taks'">{{$t('t.OnSelectedCollaborations')}}</span>
            <span v-else-if="search.documentType === 'allocation-announcements'">{{$t('t.OnSelectedAnnouncements')}}</span>
            <span v-else-if="search.documentType === 'contacts'">{{$t('t.SelectedContacts')}}</span>
            <v-chip color="secondary">{{search.selectedCount}}</v-chip>
          </div>
          <v-form
            v-model="formIsValid"
            ref="form"
          >
            <template v-for="(value, index) in actionToConfirm.acceptedValues">
              <v-switch
                :key="index"
                v-if="value.type === 'boolean'"
                :label="value.label"
                v-model="actionValues[index]"
              />
              <date-picker-menu
                :key="index"
                v-else-if="value.type === 'date'"
                :clearable="true"
                :label="value.label"
                :min="value.min"
                :rules="required"
                v-model="actionValues[index]"
                @hook:mounted="$refs.form.validate()"
              />
              <v-select
                :key="index"
                v-else-if="value.type === 'select'"
                :items="value.selectValues"
                :label="value.label"
                v-model="actionValues[index]"
              />
              <v-textarea
                :key="index"
                v-else-if="value.type === 'text'"
                :label="value.label"
                :counter="value.counter"
                :rules="getTextRules(value.counter)"
                v-model="actionValues[index]"
              />
              <picker
                :key="index"
                v-else-if="value.type === 'document'"
                class="mt-4"
                :attach="false"
                :clearable="!value.isRequired"
                :document-types="value.documentType"
                :filters="value.filters"
                :excluded-items="value.excluded()"
                :label="value.label"
                :persistent-placeholder="!!value.placeholder"
                :placeholder="value.placeholder"
                :rules="value.isRequired ? required : undefined"
                :selected-id.sync="actionValues[index]"
                :scope="value.scope()"
                :has-email="value.hasEmail"
                @hook:mounted="$refs.form.validate()"
              />
              <label-picker
                class="mt-4"
                v-else-if="value.type === 'label'"
                :key="index"
                :selection.sync="labelValues"
                :attach="false"
                @update:selection="setLabelSelection(index, $event)"
              />
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            data-cy="bulk-action-confirm"
            :loading="executionInProgress"
            :disabled="!formIsValid"
            @click="executeAction(actionValues)"
          >
            {{$t('t.Yes')}}
          </v-btn>
          <v-btn
            data-cy="bulk-action-cancel"
            color="primary"
            @click="showConfirmPopUp = false"
          >{{$t(!executionInProgress ? 't.No' : 't.ClosePopUp')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { refreshCache } from '@/wasm/pkg'
import Search from '../../controllers'
/** @typedef {import('@/pages/search/controllers/action').default} Action */

export default {
  components: {
    ActionList: () => import('./action-list'),
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    LabelPicker: () => import('@/components/label-picker'),
    Picker: () => import('@/components/picker'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      /** @type {Action} */
      actionToConfirm: undefined,
      actionValues: [],
      executionInProgress: false,
      formIsValid: false,
      mainMenuIsOpen: false,
      showConfirmPopUp: false,
      labelValues: [],
      textRules: [value => (value?.length ?? 0) <= 2000]
    }
  },
  computed: {
    groupedActions () {
      return this.lodash.groupBy(this.orderedActions, 'category')
    },
    hasMultipleGroups () {
      return Boolean(Object.keys(this.groupedActions).length > 1)
    },
    labelShowSelectedItems () {
      return !this.search.isShowSelectedItems ? this.$t('t.ShowSelectedItems') : this.$t('t.ShowAllItems')
    },
    orderedActions () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.lodash.sortBy(this.search.bulkActions, ['category', 'name'])
    },
    required () {
      return [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')]
    }
  },
  watch: {
    showConfirmPopUp (shown) {
      if (!shown) {
        this.actionToConfirm = undefined
        this.actionValues = []
        this.labelValues = []
      }
    }
  },
  methods: {
    /**
     * @param {Action} action
     */
    confirmAction (action) {
      this.mainMenuIsOpen = false
      this.actionToConfirm = action
      action.acceptedValues.forEach((acceptedValue, index) => {
        this.actionValues[index] = acceptedValue.default()
      })
      if (action.mustBeSkipConfirmation) {
        this.executeAction(action)
      } else {
        this.showConfirmPopUp = true
      }
    },
    executeAction (actionValues) {
      this.executionInProgress = true
      this.actionToConfirm.execute(actionValues)
        .then(({ isSilent = false } = {}) => {
          this.search.refreshResults()
          refreshCache()

          if (!isSilent) {
            this.$store.dispatch('showSuccessSnackbar', this.$t('t.SaveCompleted'))
          }
          this.showConfirmPopUp = false
          if (
            !this.search.keepSelectionAfterAction ||
            (this.actionToConfirm.code !== 'show-selected-items' && this.search.allItemsSelected) ||
            this.actionToConfirm.selectionMustBeCleared
          ) {
            this.search.selectAllItems(false)
          }
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch('showErrorSnackbar', this.$t(e?.response?.data?.message ?? 't.UnknowError'))
        }).finally(() => {
          this.executionInProgress = false
        })
    },
    openDefaultMenu (category) {
      if (['invoices', 'accounts', 'account-contacts', 'disputes', 'messages-inbox', 'messages-outbox'].includes(this.search.documentType)) {
        return category === this.$t('t.Create')
      }
      return null
    },
    setLabelSelection (index, value) {
      this.actionValues[index] = value
      this.labelValues = value
    },
    getTextRules (counter) {
      if (counter > 0) {
        return [value => (value?.length ?? 0) <= counter]
      } else { return [true] }
    }
  },
  props: {
    search: Search
  }
}
</script>
